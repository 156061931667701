import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      meta: {
        requiresAuth: true,
        rule: "worker",
      },
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("@/views/Home.vue"),
      meta: {
        rule: "worker",
        requiresAuth: true,
        pageTitle: "Дашборд",
        breadcrumb: [
          {
            text: "Дашборд",
            active: true,
          },
        ],
      },
    },
    {
      path: "/appointments",
      name: "appointments",
      component: () => import("@/views/Appointments.vue"),
      meta: {
        rule: "worker",
        requiresAuth: true,
        pageTitle: "Записи на прийом",
        breadcrumb: [
          {
            text: "Записи на прийом",
            active: true,
          },
        ],
      },
    },
    {
      path: "/tickets",
      name: "tickets",
      redirect: "/tickets/all",
      component: () => import("@/views/Tickets.vue"),
      children: [
        {
          path: "all",
          name: "tickets-all",
          component: () => import("@/views/tickets/all.vue"),
          meta: {
            rule: "worker",
          },
        },
        {
          path: "t/:id",
          name: "ticket-single",
          meta: {
            rule: "worker",
            requiresAuth: true,
          },
          component: () => import("@/views/tickets/single-ticket.vue"),
        },
      ],
      meta: {
        rule: "worker",
        requiresAuth: true,
        pageTitle: "Всі звернення",
        breadcrumb: [
          {
            text: "Всі звернення",
            active: true,
          },
        ],
      },
    },
    {
      path: "/profile",
      name: "profile",
      component: () => import("@/views/Profile.vue"),
      meta: {
        rule: "worker",
        requiresAuth: true,
        pageTitle: "Мій профіль",
        breadcrumb: [
          {
            text: "Мій профіль",
            active: true,
          },
        ],
      },
    },
    {
      path: "/my-profile",
      name: "my-profile",
      component: () => import("@/views/Profile.vue"),
      meta: {
        rule: "worker",
        requiresAuth: true,
        pageTitle: "Мій профіль",
        breadcrumb: [
          {
            text: "Мій профіль",
            active: true,
          },
        ],
      },
    },
    {
      path: "/users",
      name: "users",
      redirect: "/users/all",
      component: () => import("@/views/Users.vue"),
      children: [
        {
          path: "all",
          name: "users-all",
          component: () => import("@/views/users/all.vue"),
          meta: {
            rule: "admin",
            pageTitle: "Усі користувачі",
            breadcrumb: [
              {
                text: "Усі користувачі",
                active: true,
              },
            ],
          },
        },
        {
          path: "u/:id",
          name: "user-single",
          component: () => import("@/views/users/single-user.vue"),
          meta: {
            rule: "admin",
            pageTitle: "Усі користувачі",
            breadcrumb: [
              {
                text: "Усі користувачі",
                active: false,
                to: "/users/all",
              },
              {
                text: "Користувач",
                active: true,
              },
            ],
          },
        },
      ],

      meta: {
        rule: "admin",
        requiresAuth: true,
        pageTitle: "Користувачі",
        breadcrumb: [
          {
            text: "Користувачі",
            active: true,
          },
        ],
      },
    },
    {
      path: "/employees",
      name: "employees",
      component: () => import("@/views/Employees.vue"),
      meta: {
        rule: "worker",
        requiresAuth: true,
        pageTitle: "Працівники",
        breadcrumb: [
          {
            text: "Працівники",
            active: true,
          },
        ],
      },
    },
    {
      path: "/departments",
      name: "departments",
      redirect: "/departments/all",
      component: () => import("@/views/Departments.vue"),
      children: [
        {
          path: "all",
          name: "departments-all",
          component: () => import("@/views/departments/all"),
          meta: {
            rule: "worker",
            requiresAuth: true,
            pageTitle: "Відділи, підрозділи",
            breadcrumb: [
              {
                text: "Відділи, підрозділи",
                active: true,
              },
            ],
          },
        },
        {
          path: "d/:id",
          name: "departments-single",
          component: () => import("@/views/departments/single-department.vue"),
          meta: {
            rule: "worker",
            requiresAuth: true,
            pageTitle: "Відділи, підрозділи",
            breadcrumb: [
              {
                text: "Відділи, підрозділи",
                active: false,
                to: "/departments/all",
              },
              {
                text: "Відділ",
                active: true,
              },
            ],
          },
        },
      ],
      meta: {
        rule: "worker",
        requiresAuth: true,
        pageTitle: "Відділи, підрозділи",
        breadcrumb: [
          {
            text: "Відділи, підрозділи",
            active: true,
          },
        ],
      },
    },
    {
      path: "/topics",
      name: "topics",
      component: () => import("@/views/topics/all.vue"),
      meta: {
        rule: "admin",
        requiresAuth: true,
        pageTitle: "Теми звернень",
        breadcrumb: [
          {
            text: "Теми звернень",
            active: true,
          },
        ],
      },
    },
    {
      path: "/moderation",
      name: "moderation",
      component: () => import("@/views/moderation/all.vue"),
      meta: {
        rule: "admin",
        requiresAuth: true,
        pageTitle: "Модерація відповідей",
        breadcrumb: [
          {
            text: "Модерація відповідей",
            active: true,
          },
        ],
      },
    },
    {
      path: "/services",
      name: "services",
      redirect: "/services/all",
      component: () => import("@/views/Services.vue"),
      children: [
        {
          path: "all",
          name: "services-all",
          component: () => import("@/views/services/all"),
          meta: {
            rule: "admin",
            requiresAuth: true,
            pageTitle: "Всі послуги",
            breadcrumb: [
              {
                text: "Всі послуги",
                active: true,
              },
            ],
          },
        },
        {
          path: "s/:slug",
          name: "service-single",
          component: () => import("@/views/services/single-service.vue"),
          meta: {
            rule: "admin",
            requiresAuth: true,
            pageTitle: "Всі сервіси",
            breadcrumb: [
              {
                text: "Всі сервіси",
                active: false,
                to: "/services/all",
              },
              {
                text: "Сервіс",
                active: true,
              },
            ],
          },
        },
      ],
      meta: {
        rule: "worker",
        requiresAuth: true,
        pageTitle: "Відділи, підрозділи",
        breadcrumb: [
          {
            text: "Відділи, підрозділи",
            active: true,
          },
        ],
      },
    },
    {
      path: "/statuses",
      name: "statuses",
      component: () => import("@/views/Statuses.vue"),
      meta: {
        rule: "worker",
        requiresAuth: true,
        pageTitle: "Статуси заявок",
        breadcrumb: [
          {
            text: "Статуси заявок",
            active: true,
          },
        ],
      },
    },
    {
      path: "/statistic",
      name: "statistic",
      component: () => import("@/views/Statuses.vue"),
      meta: {
        rule: "worker",
        requiresAuth: true,
        pageTitle: "Статистика",
        breadcrumb: [
          {
            text: "Статистика",
            active: true,
          },
        ],
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        rule: "anonymous",
        layout: "full",
        requiresAuth: false,
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        rule: "anonymous",
        layout: "full",
        requiresAuth: false,
      },
    },
    {
      rule: "anonymous",
      path: "*",
      redirect: "error-404",
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && store.state.user == null) {
    next({ name: "login" });
  }

  next();
});

export default router;
