import Vue from "vue";
import { ToastPlugin, ModalPlugin, BootstrapVue } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";

import router from "./router";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";

import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBPvGu_7Ar65JokuJNFaAS0Q6pakZ7Qr5k",
  authDomain: "stryi-citizen.firebaseapp.com",
  projectId: "stryi-citizen",
  storageBucket: "stryi-citizen.appspot.com",
  messagingSenderId: "88096356509",
  appId: "1:88096356509:web:05731ed57f975d8fb23c74",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//Firebase Auth services
import auth from "./providers/auth";
Vue.prototype.$auth = auth(app);

//Firebase Auth services
import db from "./providers/db";
Vue.prototype.$db = db(app);

//AXIOS as API
import api from "./providers/api";
Vue.prototype.$api = api.axiosApi;
Vue.prototype.$fileApi = api.fileApi;

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(BootstrapVue);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

//////////////////////////////
// ACL - managing user roles
//////////////////////////
import acl from "./acl/acl";

Vue.config.productionTip = false;

new Vue({
  // acl,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
