import axios from "axios";

const axiosApi = axios.create({
  //Base URL for API calls
  baseURL: "https://us-central1-stryi-citizen.cloudfunctions.net/api",
  // baseURL: "http://localhost:5001/stryi-citizen/us-central1/api",
  // withCredentials: true,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

const fileApi = axios.create({
  //Base URL for API calls
  baseURL: "https://stryi-rada.gov.ua/wp-json/myplugin/v1/upload-file",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});
let api = {
  axiosApi: axiosApi,
  fileApi: fileApi,
};

export default api;
